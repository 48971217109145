import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { projectAuth } from "./firebase/config";
import registerPrimeVue from "./primevue/config";
import registerBaseComponents from "./components";
import registerDirectives from "./directives";
// import { createI18n } from "vue-i18n";
// import messages from "./i18n/messages";

// const i18n = createI18n({
//   locale: "fr",
//   fallbackLocale: "en",
//   messages,
// });

let app;
projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    app.use(router);
    // app.use(i18n);
    registerPrimeVue(app);
    registerBaseComponents(app);
    registerDirectives(app);
    app.mount("#app");
  }
});
