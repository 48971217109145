import { projectDatabase } from "@/firebase/config";

export default {
  can: {
    mounted: async (el, binding) => {
      const res = await projectDatabase.ref("users/roles").get();
      const roles = res.val();
      if (
        !roles[binding.value].rules ||
        !roles[binding.value].rules[binding.arg]
      ) {
        el.parentNode.removeChild(el);
      }
    },
  },
};
