import security from "./security";

let directives = {
  focus: {
    mounted(el) {
      el.focus();
    },
  },
  required: {
    mounted(el) {
      const asterisk = document.createElement("span");
      asterisk.innerHTML = "*";
      asterisk.style.padding = "0.1rem";
      asterisk.style.color = "red";
      el.appendChild(asterisk);
    },
  },
  attr: {
    mounted(el, binding) {
      el.setAttribute(binding.arg, binding.value);
    },
  },
  breakpoint: {
    mounted(el, binding) {
      const wrapper = document.createElement("div");
      el.insertBefore(wrapper, el.children);
      if (window.innerWidth < binding.arg) {
        // el.parentNode.removeChild(el);
        console.log(wrapper);
      }
    },
  },
  formatCurrency: {
    mounted(el, binding) {
      if (binding.value === 0) {
        el.innerHTML = "Indisponible";
      } else {
        el.innerHTML = numStr(binding.value) + " €";
      }
    },
  },
};

directives = { ...directives, ...security };

export default function (app) {
  Object.entries(directives).forEach((d) => {
    app.directive(d[0], d[1]);
  });
}

function numStr(a, b) {
  a = "" + a;
  b = b || " ";
  var c = "",
    d = 0;
  while (a.match(/^0[0-9]/)) {
    a = a.substr(1);
  }
  for (var i = a.length - 1; i >= 0; i--) {
    c = d != 0 && d % 3 == 0 ? a[i] + b + c : a[i] + c;
    d++;
  }
  return c;
}
