import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBnaF9vSNtHvIdXh4KR30HUPTxfmN3WeTM",
  authDomain: "application-inventaires.firebaseapp.com",
  databaseURL: "https://6sb2gu992w4b.europe-west1.firebasedatabase.app",
  projectId: "application-inventaires",
  storageBucket: "application-inventaires.appspot.com",
  messagingSenderId: "192846224574",
  appId: "1:192846224574:web:4473e4dc6791119fbf92ef",
  measurementId: "G-E4ET70YSBZ",
};

// Init
firebase.initializeApp(firebaseConfig);

// Services
const projectAuth = firebase.auth();
const projectFunctions = firebase.functions();
const projectDatabase = firebase.database();
const authPersistence = firebase.auth.Auth.Persistence;
const serverValue = firebase.database.ServerValue;

// Emulators
if (process.env.NODE_ENV !== "production") {
  firebase.functions().useEmulator("localhost", 5001);
  firebase.database().useEmulator("localhost", 9000);
  firebase
    .auth()
    .useEmulator("http://localhost:9099", { disableWarnings: true });
}

export {
  projectAuth,
  projectDatabase,
  projectFunctions,
  authPersistence,
  serverValue,
};
