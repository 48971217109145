import PrimeVue from "primevue/config";
import Ripple from "primevue/ripple";
import locale from "./locale";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../assets/_primevue.scss";
require("./filters.js");

import Button from "primevue/button";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import ConfirmPopup from "primevue/confirmpopup";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Avatar from "primevue/avatar";
import PanelMenu from "primevue/panelmenu";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import ProgressSpinner from "primevue/progressspinner";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Calendar from "primevue/calendar";
import Toast from "primevue/toast";
import Toolbar from "primevue/toolbar";
import Breadcrumb from "primevue/breadcrumb";
import ToggleButton from "primevue/togglebutton";
import InputMask from "primevue/inputmask";
import Chip from "primevue/chip";
import Menu from "primevue/menu";
import AutoComplete from "primevue/autocomplete";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import ColorPicker from "primevue/colorpicker";
import ConfirmDialog from "primevue/confirmdialog";
import Tooltip from "primevue/tooltip";
import Tag from "primevue/tag";

export default (app) => {
  app.use(PrimeVue, { locale, ripple: true, inputStyle: "filled" });
  app.use(ConfirmationService);
  app.use(ToastService);
  app.directive("ripple", Ripple);
  app.directive("Badge", BadgeDirective);
  app.directive("Tooltip", Tooltip);
  app.component("Button", Button);
  app.component("Card", Card);
  app.component("InputText", InputText);
  app.component("InputNumber", InputNumber);
  app.component("InputMask", InputMask);
  app.component("Password", Password);
  app.component("Checkbox", Checkbox);
  app.component("Dialog", Dialog);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("Avatar", Avatar);
  app.component("PanelMenu", PanelMenu);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("ColumnGroup", ColumnGroup);
  app.component("Dropdown", Dropdown);
  app.component("MultiSelect", MultiSelect);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("Accordion", Accordion);
  app.component("AccordionTab", AccordionTab);
  app.component("Calendar", Calendar);
  app.component("Toast", Toast);
  app.component("Toolbar", Toolbar);
  app.component("Breadcrumb", Breadcrumb);
  app.component("ToggleButton", ToggleButton);
  app.component("Chip", Chip);
  app.component("Menu", Menu);
  app.component("AutoComplete", AutoComplete);
  app.component("Badge", Badge);
  app.component("ColorPicker", ColorPicker);
  app.component("Tag", Tag);
};
