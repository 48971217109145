<template>
  <div class="progress-mask">
    <ProgressSpinner><slot></slot></ProgressSpinner>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.progress-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: $primary-dark;
  }
  40% {
    stroke: $primary-light;
  }
  66% {
    stroke: $primary-dark;
  }
  80%,
  90% {
    stroke: $primary-light;
  }
}
</style>
