<template>
  <div class="node-env-status" v-if="env !== 'production'">
    Development Mode (Working on Emulators)
  </div>
  <Toast />
  <ConfirmPopup />
  <div class="app p-input-filled">
    <router-view />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  setup() {
    const env = ref(process.env.NODE_ENV);
    onMounted(() => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
      document.documentElement.classList.add("p-input-filled");
    });

    return { env };
  },
};
</script>

<style lang="scss" src="./assets/_styles.scss" />
